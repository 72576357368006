import { tagOf } from "../../../classes/misc-util";
import { ACTION_INDICATOR_VALUES } from "./list.public";
export function isInstanceOfString(object) {
    return typeof object === "string" || object instanceof String;
}
export var LogoSizes;
(function (LogoSizes) {
    LogoSizes[LogoSizes["XL"] = 72] = "XL";
    LogoSizes[LogoSizes["L"] = 62] = "L";
    LogoSizes[LogoSizes["M"] = 48] = "M";
    LogoSizes[LogoSizes["S"] = 32] = "S";
    LogoSizes[LogoSizes["XS"] = 24] = "XS";
})(LogoSizes || (LogoSizes = {}));
export function isValidActionIndicatorValue(actionIndicatorType) {
    return ACTION_INDICATOR_VALUES.includes(actionIndicatorType);
}
export function isListItemElement(element) {
    return tagOf(element) === "dx-list-item";
}
